import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";
import ThemeBox from "../../components/bricks/ThemeBox";
import {themeBright, themeDark} from "../../page-styles/landingpage.css";

const VsJira = ({location}) => (
  <DefaultLayout
    title="Codecks vs Jira"
    description="Codecks is like a playful Atlassian Jira but for game development. Here is why."
    location={location}
  >
    <Hero
      size="sm"
      title="Codecks vs Jira"
      subline="Why deal with complicated enterprise tools that were created for organizations, which have different processes, challenges and cultures than game companies? Codecks is the best choice for gamedev."
    />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature childAsDescription img="jira-1.png" title="Less options, more Joy">
        Codecks is much simpler than Jira by design. Jira allows to enforce a lot of rules on your
        team members. Codecks favors flexibility and educated teams. For example when you want to
        create a task in Codecks, it's straight forward: just create a card. No need to
        differentiate between different issue types or content forms.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="cards-3.png"
        imgRight
        title="Inspired by Great Games"
      >
        Project management tools don't have to be ugly or boring. Codecks uses a unique playful
        trading card game paradigm, which takes learnings about information density and data
        comprehension from the best game developers out there and applies them to your game
        development plan.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="jira-2.png"
        title="Performance has highest priority"
      >
        Quickly updating a task can become surprisingly time-intensive as your Jira project grows.
        Jira being slow is the kind of friction will likely lead to frustration within your team. We
        at Codecks are dedicated to snappy experience, no matter the size of your project.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="decks-3.png"
        imgRight
        title="Game Design Documents"
      >
        Codecks uses decks as container for cards. This allows you to create a deck for each feature
        type to slice your backlog into manageable chunks. Hundreds of cards can be effectively
        managed this way and since cards can contain whole text documents and attachments, they are
        also a great way for organizing your game design document.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeDark} withBorder title="Let's have a conversation about conversations">
      <ThemedSingleFeature childAsDescription img="conversations-notifications-1.png">
        Having productive conversations is a corner-piece of any successful project managed by more
        than one person. This is why we focussed a lot of our attention to nail this part of the
        process. We have come up with a unique conversation system that is aimed at preventing loose
        ends.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="conversations-1.png"
        imgRight
        title="Each idea deserves their own thread"
      >
        All too often it happens that people get notified of a comment but are not in a position to
        reply right away. Hardly any tool approaches this common problem effectively and Jira is no
        exception. Codecks employs a few ideas to ensure that the no comment gets lost. First off,
        every conversation happens inside a thread. This not only allows to have separate
        discussions on a single cards, but also allows you to define who is part of a conversation
        and whether this conversation can be closed.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="conversations-4.png"
        title="Who takes part and did we come to a conclusion?"
      >
        When creating a conversation, you may select the participants either from a list of common
        conversation partners or by simply mentioning them in your message. Every participant will
        get notified of this conversation and Codecks ensures that the conversation comes to a
        conclusion by regularly reminding all participants of any open conversation. If people feel
        like they can't contribute any more, they can always opt out.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="conversations-2.png"
        imgRight
        title="Highlight important conversations"
      >
        Even more interestingly, two workflow states are directly bound to conversations. Both,
        blocking a card and reviews, are initiated by starting a conversation. The card will remain
        in that highly visible state and send regular reminders to all particpants until the
        conversation has been marked as resolved.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default VsJira;
